@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

html, body {
  background-color: black;
  background-image: radial-gradient(
    rgba(41, 80, 139, 0.75), black 120%
  );
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  color: rgb(255, 255, 255);
  font-family: 'Ubuntu mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:flex;
  align-items:center;
}

.nowrap {
  white-space: nowrap;
}